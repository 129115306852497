@import "../../responsive.scss";

.Teams {
  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    box-shadow: 0 0 10px 0 hsl(0deg 0% 82% / 50%);
    background-color: white;
    color: black;
    padding: 1rem;
    box-sizing: border-box;
    border: 0 solid;
    width: 90%;

    @include mobile {
      width: 100%;
    }
  }
}
