@import "../../responsive.scss";

.AboutUs {
  .section-one {
    .image {
      object-fit: cover;
      width: 100vw;

      @include mobile {
        height: 200px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      .title {
        margin-top: 2%;
        color: #d39444;
        font-size: x-large;
        font-family: "Lora";
      }

      .message {
        margin-left: 10vw;
        margin-right: 10vw;
        font-weight: 300;
        text-indent: 10%;
      }
    }
  }

  .section-two {
    padding-top: 5%;
    .content {
      padding-left: 10%;
      display: flex;
      flex-direction: row;
      height: 280px;

      @include mobile {
        height: 100%;
      }

      .left {
        .image {
          object-fit: cover;
          height: 300px;
          width: 400px;

          @include mobile {
            display: none;
          }
        }
      }
      .right {
        padding: 3%;
        // overflow: scroll;

        @include mobile {
          padding: 0%;
        }

        .title {
          color: #d39444;
          font-family: "Lora";
          font-size: large;
        }
        .message {
          font-size: medium;
          font-weight: 300;
          width: 35vw;

          @include mobile {
            width: 90%;
          }
        }
      }
    }
  }

  .section-three {
    padding: 7%;

    .container {
      background-color: #3d3b3c;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5%;

        .title {
          color: #d39444;
          font-family: "Lora";
          font-size: x-large;
        }

        .message {
          color: white;
          text-indent: 5%;
          font-weight: 300;
          margin-top: 10px;
          line-height: 24px;
        }
      }
    }
  }
}
