@import "../../responsive.scss";

.Properties {
  .background {
    object-fit: cover;
    width: 100vw;

    @include mobile {
      height: 200px;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2%;

    .title {
      color: #d39444;
      font-family: "Lora";
      font-size: x-large;
      margin-bottom: 3%;
    }

    .content {
      margin-bottom: 3%;
      width: 100%;

      .cards {
        margin-left: 10%;
        width: 80%;

        .card {
          cursor: pointer;
          margin-top: 5%;
          overflow: hidden;
          width: 100%;
          height: 98%;

          .previewImage {
            height: 350px;
            object-fit: cover;
          }

          .address {
            margin-top: 1%;
          }
          .item {
            margin-top: 3%;

            .label {
              color: #d39444;
              font-family: "Lora";
            }

            .value {
              font-family: "Lora";
            }
          }
          .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin-top: -2%;

            .details-btn {
              background-color: #d39444;
              color: white;
              border-color: #c7862f;
            }
          }

          .addressContainer {
            display: flex;
            flex-direction: row;
            .icon {
              color: #d39444;
            }
          }
        }
      }
    }
  }
}
