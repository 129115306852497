.Admin {
    .header {
        color: white;
        justify-content: right;
        align-items: center;
        text-align: center;
        display: flex;
        
        .userInfo {
            display: flex;
            align-items: center;
            justify-content: center;

            .user {
                padding-right: 10px;
                padding-left: 10px;
            }

            .button {
                padding-right: 10px;
                cursor: pointer;
            }
        }
    }
}