.SubscribeForm {
  .subscribe-button {
    margin-left: 2%;
    height: 30px;
    width: 100px;
    cursor: pointer;
    color: white;
    border-color: #d49444;
  }
}
