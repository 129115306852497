@import "../../responsive.scss";

.Navigation {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97%;
    position: absolute;
    left: 3vw;
    top: 8%;
    margin-top: -2%;
    overflow: hidden;

    .logo {
      width: 15vw;

      @include mobile {
        width: 30vw;
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      margin-top: -3%;

      .menu-item {
        margin-top: -1%;
        margin-right: 50px;
        color: white;

        @include mobile {
          display: none;
        }
      }

      .menu-item:hover {
        border: 1px solid white;
        transition: all 0.1s ease;
      }

      .primary-button {
        background-color: #d39444;
        margin-top: -1%;
        margin-right: 2%;
      }

      .primary-button:hover {
        background-color: rgba(255, 255, 255, 0.922);
        color: #ff9100;
      }

      .default-button {
        margin-top: -1%;
        background-color: transparent;
      }

      .default-button:hover {
        background-color: rgba(255, 255, 255, 0.922);
        color: #ff9100;
        border-color: white;
      }
    }
    .hamburger {
      display: none;

      .hamburger-icon {
        color: white;
        width: 50px;
        margin-left: -45%;
        margin-top: -10%;
        font-size: 30px;

        :hover {
          color: #fe9409;
          transition: all 0.3s ease;
        }
      }

      @include mobile {
        display: flex;
      }
    }
  }
}
