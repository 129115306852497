@import "../../responsive.scss";

.LandingPage {
  .section-one {
    height: 100vh;
    width: 100vw;

    .background {
      object-fit: cover;
      width: 100vw;
      height: 100% !important;
    }

    // .header {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   width: 97%;
    //   position: absolute;
    //   left: 3vw;
    //   top: 8%;
    //   margin-top: -2%;
    //   overflow: hidden;

    //   .logo {
    //     width: 15vw;
    //   }

    //   .menu {
    //     display: flex;
    //     flex-direction: row;
    //     margin-top: -3%;

    //     .menu-item {
    //       margin-top: -1%;
    //       margin-right: 50px;
    //       color: white;
    //     }

    //     .primary-button {
    //       background-color: #d39444;
    //       margin-top: -1%;
    //       margin-right: 2%;
    //     }

    //     .default-button {
    //       margin-top: -1%;
    //       background-color: transparent;
    //     }
    //   }
    // }

    .content {
      position: absolute;
      top: 35%;
      left: 8%;
      width: 320px;
      font-size: 35px;

      .title {
        color: white;
        font-family: "Lora";
      }

      .o-t {
        color: #d39444;
        font-family: "Lora";
        font-weight: bold;
      }

      .divider {
        background-color: #d39444;
        height: 4px;
      }

      .properties {
        background-color: #d39444;
      }

      .properties:hover {
        background-color: rgba(255, 255, 255, 0.922);
        color: #ff9100;
      }
    }
  }

  .section-two {
    padding: 10%;
    padding-bottom: 8%;

    .content {
      display: flex;
      flex-direction: row;
      height: 100%;

      .image {
        width: 40vw;
        height: 100%;
        object-fit: cover;

        @include mobile {
          display: none;
        }
      }

      .right {
        // overflow: scroll;

        .text {
          margin-left: 5%;
          margin-top: 3%;
          font-family: "Lora";
          font-size: x-large;

          .heading {
            font-family: "Lora";
            color: #d39444;
          }

          .divider {
            background-color: #d39444;
            height: 1px;
          }

          .message {
            font-size: medium;
          }

          .button {
            margin-top: 10%;
            .about-us {
              border-color: #d39444;
              color: #d39444;
              padding: 0 10% 0 10%;
              @include mobile {
                width: 100%;
              }
            }
            .about-us:hover {
              background-color: #d39544e0;
              color: white;
            }
          }
        }
      }
    }
  }

  .section-three {
    height: 600px;

    @include mobile {
      height: 250px;
    }

    .items {
      .content {
        height: 498px;
        background: linear-gradient(to left, #3d3b3c 50%, white 50%);

        @include mobile {
          background: none;
        }

        .title {
          .left {
            margin-left: 8%;
            width: 40%;

            @include mobile {
              margin-left: 14%;
              margin-right: 14%;
              width: 75%;
            }

            .text {
              .heading {
                font-size: x-large;
                font-family: "Lora";
                color: #d39444;
              }
              .message {
                .highlights {
                  font-weight: bolder;
                  font-style: oblique;
                  color: #d78822;
                }
              }
            }
          }
        }

        .images {
          display: flex;
          flex-direction: row;
          padding-left: 15%;
          padding-top: 3%;
          width: 85%;
          // overflow: scroll;

          @include mobile {
            display: none;
          }

          .image {
            margin-right: 30px;
            width: 300px;
            height: 400px;
            cursor: pointer;
          }
        }

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2%;

          @include mobile {
            margin-top: 5%;
            margin-bottom: 5%;
          }

          .ant-btn {
            border-color: #d39444;
            background-color: transparent;
            color: rgb(78, 78, 78);
            padding: 0 10% 0 10%;

            @include mobile {
              background-color: #d39544ec;
              color: white;
            }
          }

          .ant-btn:hover {
            background-color: #d39544e0;
            color: white;
          }
        }
      }
    }
  }

  .section-four {
    margin-top: calc(6vh);
    padding-bottom: 4%;
    background-color: rgba(50, 48, 49, 0.08);

    .title {
      padding-top: 4%;
      font-family: "Lora";
      display: flex;
      justify-content: center;
      color: #d39444;
      font-size: x-large;
      padding-bottom: 3%;
    }

    .cards {
      margin-left: 5%;
      margin-right: 5%;

      .card {
        .ant-card-body {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column !important;
          height: 150px;
        }

        .card-content {
          color: rgb(77, 77, 77);
          font-size: xx-large;
          font-weight: 500;
          font-family: "Lora";
        }

        .card-label {
          color: rgb(199, 198, 198) !important;
          font-family: "Lato";
        }
      }
    }
  }

  .section-five {
    .content {
      height: 530px;
      background: linear-gradient(to left, white 50%, #3d3b3c 50%);

      @include mobile {
        background: none;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 5%;
      }

      .left {
        margin-left: 5%;
        padding-top: 3%;

        .title {
          color: #d39444;
          font-family: "Lora";
          font-weight: 500;
          font-size: x-large;
        }
        .message {
          margin-top: 1%;
          color: white;
          width: 40vw;

          @include mobile {
            color: black;
            width: 100%;
          }

          .highlights {
            font-weight: bolder;
            font-style: oblique;
            color: #d78822;
          }
        }
      }

      .right {
        margin-left: 54%;
        margin-top: -17vh;

        @include mobile {
          margin-left: 5%;
          margin-top: 5%;
        }

        .title {
          color: #d39444;
          font-family: "Lora";
          font-weight: 500;
          font-size: x-large;
        }
        .message {
          margin-top: 1%;
          color: black;
          width: 40vw;
          font-size: medium;

          @include mobile {
            width: 100%;
          }

          .highlights {
            font-weight: bolder;
            font-style: oblique;
            color: #d78822;
          }
        }
      }

      .icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 9.5%;

        @include mobile {
          display: none;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .icon-img {
            width: 30px;
          }
          .right-label {
            font-family: "Lora";
          }
          .left-label {
            color: white;
            font-family: "Lora";
          }
        }
      }
    }
  }

  .section-six {
    .container {
      background-image: url("../../images/ready-to-buy-bg.png");
      height: 500px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .transbox {
      padding: 30px;
      background-color: rgba(255, 255, 255, 0.5);
      border: 1px solid #d39444;
      height: 40%;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;

      .title {
        color: #d39444;
        font-family: "Lora";
      }

      .message {
        margin-top: 5%;
        align-items: center;
        font-family: "Lato";
        font-weight: 300;
        text-align: center;
      }

      .button {
        margin-top: 5%;

        .ant-btn {
          // border-color: #d39444;
          border: none;
          background-color: transparent;
          color: rgb(78, 78, 78);
          padding: 0 20% 0 20%;

          :hover {
            color: #ad6d1a;
          }
        }
      }
    }
  }
}
