.PropertyQuickLook {
  .mainDetails {
  }
  .item {
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;

    .label {
      color: black;
      font-weight: 200;
      font-family: "Lora";
    }
    .value {
      font-weight: 300;
      margin-left: 1%;
    }
    .icon {
      margin-right: 2%;
      color: #d39444;
      padding-top: 2;
    }
  }
  .desc {
    .description-container {
      width: 280px;
      .value-description {
        font-weight: 300;
      }
    }
  }
}
