@import "../../responsive.scss";

.ContactUs {
  .section-one {
    .image {
      object-fit: cover;
      width: 100vw;
      height: 400px;

      @include mobile {
        height: 200px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      .title {
        margin-top: 2%;
        color: #d39444;
        font-size: x-large;
        font-family: "Lora";
      }

      .message {
        margin-left: 10vw;
        margin-right: 10vw;
        font-weight: 300;
        text-indent: 10%;

        .formContainer {
          display: flex;
          flex-direction: row;
          margin-top: 4%;

          @include mobile {
            flex-direction: column;
          }

          .left {
            width: 50%;

            @include mobile {
              width: 100%;
            }

            .section {
              margin-top: 2%;

              .text {
                text-indent: 0;
                font-size: large;
                font-weight: 300;
                font-family: "Lato";
                margin-bottom: 10%;
              }
              .information {
                text-indent: 0;
                margin-top: 10%;
                .company {
                  font-weight: bolder;
                  font-size: large;
                  color: black;
                }
                .email {
                  font-weight: 400;

                  .link {
                    color: black;
                    color: #d39444;
                  }
                }
                .icon {
                  margin-top: 2%;
                  margin-right: 1%;

                  @include mobile {
                    margin-bottom: 10%;
                  }
                }
              }
            }
          }
          .right {
            width: 60%;
            margin-bottom: 5%;

            @include mobile {
              width: 100%;
              // margin-left: -15%;
            }
            
            .send-button {
              margin-left: -5%;
              margin-bottom: 4%;

              @include mobile {
                width: 100%;
                // margin-left: 0%;
              }
            }

            .message {
              margin-left: -5%;

              @include mobile {
                width: 100%;
                // margin-left: 0%;
              }
            }
          }
        }
      }
      .result {
        // margin-top: 2%;
        margin-left: 5%;
        font-weight: bolder;
        color: #d39444;
      }
    }
  }
}
