.AdminLogin {
  height: 100vh;
  width: 100vw;
  background-image: url("../../images/adminLogin.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    color: white;
    border: 1px dotted #ffffff;
    border-radius: 20px;
    padding: 30px 100px;

    .title {
      font-weight: bold;
      text-align: center;
      font-size: 20px;
    }
  }
}
