@import "../../responsive.scss";

.Footer {
  .section-seven {
    background-color: #3d3b3c;
    height: 200px;

    @include mobile {
      height: 100%;
    }

    .message {
      padding-top: 2%;
      margin-bottom: -1%;
      margin-left: 5%;
      color: white;
      font-family: "Lora";
      span {
        color: #d39444;
      }
    }

    .content {
      padding-top: 3%;
      padding-left: 5%;
      padding-right: 5%;
      display: flex;
      flex-direction: row;
      color: white;

      @include mobile {
        display: flex;
        flex-direction: column;
      }

      .left {
        width: 400px;

        @include mobile {
          width: 100%;
        }

        div {
          font-weight: bold;
          margin-bottom: 2%;
        }
        span {
          color: #d39444;
        }

        .link {
          color: #d39444;
        }
      }

      .middle {
        width: 200px;

        @include mobile {
          margin-top: 2%;
        }

        span {
          font-weight: bold;
        }
        .icons {
          margin-top: 2%;

          .icon {
            cursor: pointer;
            margin-right: 15px;
            width: 20px;
            height: 20px;
            font-size: 20px;
            color: rgb(155, 155, 155);

            :hover {
              color: white;
            }
          }
        }
      }

      .right {
        overflow: hidden;
        font-size: 8px;

        span {
          font-size: 16px;
          font-weight: bold;
        }

        @include mobile {
          margin-top: 2%;
          margin-bottom: 3%;
        }
      }

      .divider {
        background-color: #d39444;
        height: 70px;
        margin-left: 4%;
        margin-right: 4%;
        width: 4px;

        @include mobile {
          display: none;
        }
      }
    }
    .disclaimer {
      margin-top: 2%;
      color: white;
      font-size: 9px;
      width: 100%;
      align-items: center;
      text-align: center;
    }
  }
}
